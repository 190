import type { ParamMatcher } from "@sveltejs/kit";

export const categoriesList = [
    "credito-habitacao",
    "credito-pessoal",
    "institucional",
    "seguros",
    "imobiliario",
    "poupanca",
];

export const match = ((param) => {
    return Boolean(param === null || categoriesList.includes(param));
}) satisfies ParamMatcher;
